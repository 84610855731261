window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.privacy_terms_footer = window.locales.am.navigation.privacy_terms_footer || {};window.locales.am.navigation.privacy_terms_footer = {...window.locales.am.navigation.privacy_terms_footer, ...{
    "privacy_policy": "የግላዊነት ፖሊሲ",           
    "terms": "መመሪያዎች"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.privacy_terms_footer = window.locales.ar.navigation.privacy_terms_footer || {};window.locales.ar.navigation.privacy_terms_footer = {...window.locales.ar.navigation.privacy_terms_footer, ...{
    "privacy_policy": "سياسة الخصوصية",
    "terms": "شروط"
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.privacy_terms_footer = window.locales.en.navigation.privacy_terms_footer || {};window.locales.en.navigation.privacy_terms_footer = {...window.locales.en.navigation.privacy_terms_footer, ...{
    "privacy_policy": "Privacy Policy",
    "terms": "Terms"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.privacy_terms_footer = window.locales.es.navigation.privacy_terms_footer || {};window.locales.es.navigation.privacy_terms_footer = {...window.locales.es.navigation.privacy_terms_footer, ...{
    "privacy_policy": "Política de privacidad",
    "terms": "Condiciones"
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.privacy_terms_footer = window.locales.it.navigation.privacy_terms_footer || {};window.locales.it.navigation.privacy_terms_footer = {...window.locales.it.navigation.privacy_terms_footer, ...{
    "privacy_policy": "politica sulla riservatezza",
    "terms": "condizioni"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.privacy_terms_footer = window.locales.zh.navigation.privacy_terms_footer || {};window.locales.zh.navigation.privacy_terms_footer = {...window.locales.zh.navigation.privacy_terms_footer, ...{
    "privacy_policy": "隐私政策",           
    "terms": "术语"           
}
};