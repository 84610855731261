window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.sessions_navigation = window.locales.am.navigation.sessions_navigation || {};window.locales.am.navigation.sessions_navigation = {...window.locales.am.navigation.sessions_navigation, ...{
    "pricing": "ዋጋ",           
    "login": "ይግቡ",           
    "join": "አሁን ይጀምሩ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.sessions_navigation = window.locales.ar.navigation.sessions_navigation || {};window.locales.ar.navigation.sessions_navigation = {...window.locales.ar.navigation.sessions_navigation, ...{
    "pricing": "السعر",           
    "login": "تسجيل الدخول",           
    "join": "لتبدأ الآن"           
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.sessions_navigation = window.locales.en.navigation.sessions_navigation || {};window.locales.en.navigation.sessions_navigation = {...window.locales.en.navigation.sessions_navigation, ...{
    "pricing": "Pricing",
    "login": "Login",
    "join": "Get Started Now"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.sessions_navigation = window.locales.es.navigation.sessions_navigation || {};window.locales.es.navigation.sessions_navigation = {...window.locales.es.navigation.sessions_navigation, ...{
    "pricing": "Precios",           
    "login": "Iniciar sesión",           
    "join": "Empezar"           
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.sessions_navigation = window.locales.it.navigation.sessions_navigation || {};window.locales.it.navigation.sessions_navigation = {...window.locales.it.navigation.sessions_navigation, ...{
    "pricing": "Tariffe",           
    "login": "Accesso",           
    "join": "Comincia Subito"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.sessions_navigation = window.locales.zh.navigation.sessions_navigation || {};window.locales.zh.navigation.sessions_navigation = {...window.locales.zh.navigation.sessions_navigation, ...{
    "pricing": "价格",           
    "login": "登录",           
    "join": "立即开始"           
}
};