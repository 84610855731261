window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.navigation_helper = window.locales.am.navigation.navigation_helper || {};window.locales.am.navigation.navigation_helper = {...window.locales.am.navigation.navigation_helper, ...{
    "you_are_offline": "ከመስመር ውጪ የሆኑ ይመስላሉ!",           
    "still_want_to_navigate": "አሁንም ወደ እዚህ ውጫዊ አገናኝ ማሰስ ይፈልጋሉ?",           
    "continue": "ቀጥል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.navigation_helper = window.locales.ar.navigation.navigation_helper || {};window.locales.ar.navigation.navigation_helper = {...window.locales.ar.navigation.navigation_helper, ...{
    "you_are_offline": "يبدو أنك غير متصل بالإنترنت!",
    "still_want_to_navigate": "هل ما زلت تريد الانتقال إلى هذا الارتباط الخارجي؟",
    "continue": "يكمل"
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.navigation_helper = window.locales.en.navigation.navigation_helper || {};window.locales.en.navigation.navigation_helper = {...window.locales.en.navigation.navigation_helper, ...{
    "you_are_offline": "It looks like you're offline!",
    "still_want_to_navigate": "Do you still want to navigate to this external link?",
    "continue": "Continue"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.navigation_helper = window.locales.es.navigation.navigation_helper || {};window.locales.es.navigation.navigation_helper = {...window.locales.es.navigation.navigation_helper, ...{
    "you_are_offline": "¡Parece que estás desconectado!",
    "still_want_to_navigate": "¿Aún desea navegar a este enlace externo?",
    "continue": "Continuar"
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.navigation_helper = window.locales.it.navigation.navigation_helper || {};window.locales.it.navigation.navigation_helper = {...window.locales.it.navigation.navigation_helper, ...{
    "you_are_offline": "Sembra che tu sia offline!",
    "still_want_to_navigate": "Vuoi ancora accedere a questo link esterno?",
    "continue": "Continua"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.navigation_helper = window.locales.zh.navigation.navigation_helper || {};window.locales.zh.navigation.navigation_helper = {...window.locales.zh.navigation.navigation_helper, ...{
    "you_are_offline": "您好像离线了！",
    "still_want_to_navigate": "您仍要导航到此外部链接吗？",
    "continue": "继续"
}
};