export function getEmojiFromEmojiKey(emojiKey: string) {
    return emojiKey
        .split('-')
        .map(hex => String.fromCodePoint(parseInt(hex, 16)))
        .join('');
}

// Matches messages that are solely 1-3 emojis, including ZWJ sequences and variation selectors
export const EMOJI_CONTENT_REGEX =
    /^(?:\p{Extended_Pictographic}(?:\uFE0F|\u200D\p{Extended_Pictographic}|[\u{1F3FB}-\u{1F3FF}])*){1,3}$/u;
