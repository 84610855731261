window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.messaging = window.locales.am.messaging || {};window.locales.am.messaging.messaging = window.locales.am.messaging.messaging || {};window.locales.am.messaging.messaging = {...window.locales.am.messaging.messaging, ...{
    "messages": "መልዕክቶች",
    "search": "ፈልግ",
    "reset": "ዳግም አስጀምር",
    "more": "ተጨማሪ",
    "no_results": "ምንም ውጤት የለም።",
    "load_more": "ተጨማሪ ጫን",
    "order": "እዘዝ",
    "chats": "ቻቶች",
    "image": "ምስል",
    "video": "ቪዲዮ",
    "file": "ፋይል",
    "today": "ዛሬ",
    "yesterday": "ትናንት",
    "Sunday": "እሁድ",
    "Monday": "ሰኞ",
    "Tuesday": "ማክሰኞ",
    "Wednesday": "እሮብ",
    "Thursday": "ሐሙስ",
    "Friday": "አርብ",
    "Saturday": "ቅዳሜ",
    "formattedDate": "{formattedDate}",
    "no_members": "ምንም አባላት የሉም",
    "profile": "መገለጫ",
    "online": "በመስመር ላይ",
    "offline": "ከመስመር ውጭ",
    "local_time": "የአካባቢ ሰዓት",
    "message": "መልእክት",
    "view_in_network": "በአውታረ መረብ ውስጥ ይመልከቱ",
    "programs": "ፕሮግራሞች",
    "additional_actions": "ተጨማሪ እርምጃዎች",
    "archive_chat": "የማህደር ውይይት",
    "block_user": "ተጠቃሚን አግድ",
    "unblock_user": "ተጠቃሚን አታግድ",
    "newMessage": "አዲስ መልእክት",
    "searchClassmates": "የክፍል ጓደኞችን እና የቅርብ ጊዜ የውይይት አጋሮችን ፈልግ...",
    "suggested": "የተጠቆመ",
    "thisConversation1": "ይህ ውይይት በመካከላቸው ብቻ ነው። ",
    "thisConversation2": " እና አንተ። ስለእነሱ የበለጠ ለማወቅ መገለጫቸውን ይመልከቱ።",
    "beginningOfHistory": "ይህ የመልእክት ታሪክህ መጀመሪያ ነው። ",
    "period": ".",
    "and": " እና ",
    "comma": " , ",
    "viewProfile": "መገለጫ ይመልከቱ",
    "toInput": "ለ፡",
    "admin": "አስተዳዳሪ",
    "you": "አንተ",
    "repliedTo": "{current} ለ {parent} ምላሽ ሰጥተዋል",
    "replyTo": "ለመልእክቱ ምላሽ ይስጡ",
    "replyToAdmin": "ለአስተዳዳሪው ምላሽ ይስጡ",
    "replyToName": "ለ {name} ምላሽ ይስጡ",
    "messageTo": "መልዕክት {name}",
    "messageGroup": "የመልእክት ቡድን",
    "startNewMessage": "አዲስ መልእክት ጀምር",
    "files": "ፋይሎች",
    "chat_information": "የውይይት መረጃ",
    "and_n_others": "እና ሌሎች {count}",
    "members": "አባላት",
    "leave_chat": "ከቻት ይውጡ",
    "location_not_set": "አካባቢ አልተዘጋጀም።",
    "search_placeholder": "ፈልግ...",
    "from": "ከ",
    "to": "ለ",
    "most_relevant": "በጣም ተዛማጅ",
    "oldest_to_newest": "ከጥንት እስከ አዲሱ",
    "newest_to_oldest": "አዲሱ እስከ አሮጌው",
    "in_chat": "በቻት ውስጥ",
    "search_all_chats": "ሁሉንም ቻቶች ፈልግ",
    "search_this_chat": "ይህንን ውይይት ይፈልጉ",
    "this_chat": "ይህ ውይይት",
    "all_chats": "ሁሉም ቻቶች",
    "profile_not_available": "መገለጫ አይገኝም",
    "reply": "መልስ",
    "copy": "ቅዳ",
    "delete": "ሰርዝ",
    "confirm_delete_this_message": "ይህ መልእክት ይሰረዝ?",
    "cancel": "ሰርዝ",
    "delete_message": "መልእክት ሰርዝ",
    "cannot_delete_message_with_replies": "ይህ መልእክት ሊሰረዝ አይችልም ምክንያቱም ምላሽ አለው.",
    "error_deleting_message": "መልእክት መሰረዝ ላይ ስህተት",
    "somethingWentWrong": "የሆነ ችግር ተፈጥሯል።",
    "engineersNotified": "መሐንዲሶች እንዲያውቁ ተደርጓል",
    "closeAndRetry": "ዝጋ እና እንደገና ሞክር",
    "deactivated": "ቦዝኗል",
    "failedMessage": "መላክ አልተሳካም።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.messaging = window.locales.ar.messaging || {};window.locales.ar.messaging.messaging = window.locales.ar.messaging.messaging || {};window.locales.ar.messaging.messaging = {...window.locales.ar.messaging.messaging, ...{
    "messages": "رسائل",
    "search": "يبحث",
    "reset": "إعادة ضبط",
    "more": "أكثر",
    "no_results": "لا توجد نتائج",
    "load_more": "تحميل المزيد",
    "order": "طلب",
    "chats": "الدردشات",
    "image": "صورة",
    "video": "فيديو",
    "file": "ملف",
    "today": "اليوم",
    "yesterday": "أمس",
    "Sunday": "الأحد",
    "Monday": "الاثنين",
    "Tuesday": "يوم الثلاثاء",
    "Wednesday": "الأربعاء",
    "Thursday": "يوم الخميس",
    "Friday": "جمعة",
    "Saturday": "السبت",
    "formattedDate": "{formattedDate}",
    "no_members": "لا يوجد أعضاء",
    "profile": "حساب تعريفي",
    "online": "متصل",
    "offline": "غير متصل",
    "local_time": "التوقيت المحلي",
    "message": "رسالة",
    "view_in_network": "عرض في الشبكة",
    "programs": "البرامج",
    "additional_actions": "الإجراءات الإضافية",
    "archive_chat": "أرشيف الدردشة",
    "block_user": "حظر المستخدم",
    "unblock_user": "إلغاء حظر المستخدم",
    "newMessage": "رسالة جديدة",
    "searchClassmates": "ابحث عن زملاء الدراسة وشركاء الدردشة الأخيرين...",
    "suggested": "مقترح",
    "thisConversation1": "هذه المحادثة فقط بين ",
    "thisConversation2": " وأنت. قم بإلقاء نظرة على ملفهم الشخصي لمعرفة المزيد عنهم.",
    "beginningOfHistory": "هذه هي بداية تاريخ رسائلك مع ",
    "period": ".",
    "and": " و ",
    "comma": " , ",
    "viewProfile": "عرض الملف الشخصي",
    "toInput": "ل:",
    "admin": "مسؤل",
    "you": "أنت",
    "repliedTo": "رد {current} على {parent}",
    "replyTo": "الرد على الرسالة",
    "replyToAdmin": "الرد على المشرف",
    "replyToName": "الرد على {name}",
    "messageTo": "الرسالة {name}",
    "messageGroup": "مجموعة الرسائل",
    "startNewMessage": "ابدأ رسالة جديدة",
    "files": "الملفات",
    "chat_information": "معلومات الدردشة",
    "and_n_others": "و {count} آخرين",
    "members": "أعضاء",
    "leave_chat": "مغادرة الدردشة",
    "location_not_set": "الموقع غير محدد",
    "search_placeholder": "يبحث...",
    "from": "من",
    "to": "ل",
    "most_relevant": "الأكثر صلة",
    "oldest_to_newest": "من الأقدم إلى الأحدث",
    "newest_to_oldest": "من الأحدث إلى الأقدم",
    "in_chat": "في الدردشة",
    "search_all_chats": "البحث في جميع الدردشات",
    "search_this_chat": "ابحث في هذه الدردشة",
    "this_chat": "هذه الدردشة",
    "all_chats": "جميع المحادثات",
    "profile_not_available": "الملف الشخصي غير متاح",
    "reply": "رد",
    "copy": "ينسخ",
    "delete": "يمسح",
    "confirm_delete_this_message": "حذف هذه الرسالة؟",
    "cancel": "يلغي",
    "delete_message": "حذف الرسالة",
    "cannot_delete_message_with_replies": "لا يمكن حذف هذه الرسالة لأنها تحتوي على رد.",
    "error_deleting_message": "خطأ في حذف الرسالة",
    "somethingWentWrong": "لقد حدث خطأ ما",
    "engineersNotified": "تم إخطار المهندسين",
    "closeAndRetry": "إغلاق وإعادة المحاولة",
    "deactivated": "تم تعطيله",
    "failedMessage": "فشل في الإرسال"
}
};window.locales.en = window.locales.en || {};window.locales.en.messaging = window.locales.en.messaging || {};window.locales.en.messaging.messaging = window.locales.en.messaging.messaging || {};window.locales.en.messaging.messaging = {...window.locales.en.messaging.messaging, ...{
    "messages": "Messages",
    "in_chat": "In chat",
    "search_all_chats": "Search All Chats",
    "search_this_chat": "Search This Chat",
    "this_chat": "This Chat",
    "all_chats": "All Chats",
    "search": "Search",
    "search_placeholder": "Search...",
    "reset": "Reset",
    "more": "More",
    "from": "From",
    "to": "To",
    "no_results": "No results",
    "no_members": "No Members",
    "load_more": "Load More",
    "order": "Order",
    "most_relevant": "Most Relevant",
    "oldest_to_newest": "Oldest to Newest",
    "newest_to_oldest": "Newest to Oldest",
    "chats": "Chats",
    "image": "Image",
    "video": "Video",
    "file": "File",
    "today": "Today",
    "yesterday": "Yesterday",
    "Sunday": "Sunday",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "formattedDate": "{formattedDate}",
    "profile": "Profile",
    "online": "Online",
    "offline": "Offline",
    "local_time": "local time",
    "message": "Message",
    "view_in_network": "View in Network",
    "programs": "Programs",
    "additional_actions": "Additional Actions",
    "archive_chat": "Archive Chat",
    "block_user": "Block User",
    "unblock_user": "Unblock User",
    "newMessage": "New Message",
    "searchClassmates": "Search classmates and recent chat partners...",
    "suggested": "Suggested",
    "thisConversation1": "This conversation is just between ",
    "thisConversation2": " and you. Check out their profile to learn more about them.",
    "beginningOfHistory": "This is the beginning of your message history with ",
    "period": ".",
    "and": " and ",
    "comma": " , ",
    "viewProfile": "View Profile",
    "toInput": "To:",
    "admin": "Admin",
    "you": "You",
    "repliedTo": "{current} replied to {parent}",
    "replyTo": "Reply to message",
    "replyToAdmin": "Reply to Admin",
    "replyToName": "Reply to {name}",
    "messageTo": "Message {name}",
    "messageGroup": "Message group",
    "startNewMessage": "Start a new message",
    "files": "files",
    "chat_information": "Chat Information",
    "and_n_others": "and {count} others",
    "members": "Members",
    "leave_chat": "Leave Chat",
    "location_not_set": "Location not set",
    "profile_not_available": "Profile not available",
    "reply": "Reply",
    "copy": "Copy",
    "delete": "Delete",
    "confirm_delete_this_message": "Delete this message?",
    "cancel": "Cancel",
    "delete_message": "Delete Message",
    "cannot_delete_message_with_replies": "This message cannot be deleted because it has a reply.",
    "error_deleting_message": "Error deleting message",
    "somethingWentWrong": "Something Went Wrong",
    "engineersNotified": "Engineers have been notified",
    "closeAndRetry": "Close and Retry",
    "deactivated": "deactivated",
    "failedMessage": "Failed to send"
}
};window.locales.es = window.locales.es || {};window.locales.es.messaging = window.locales.es.messaging || {};window.locales.es.messaging.messaging = window.locales.es.messaging.messaging || {};window.locales.es.messaging.messaging = {...window.locales.es.messaging.messaging, ...{
    "messages": "Mensajes",
    "search": "Buscar",
    "reset": "Reiniciar",
    "more": "Más",
    "no_results": "No hay resultados",
    "load_more": "Cargar más",
    "order": "Orden",
    "chats": "Charlas",
    "image": "Imagen",
    "video": "Video",
    "file": "Archivo",
    "today": "Hoy",
    "yesterday": "Ayer",
    "Sunday": "Domingo",
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "formattedDate": "{formattedDate}",
    "no_members": "Sin miembros",
    "profile": "Perfil",
    "online": "En línea",
    "offline": "Desconectado",
    "local_time": "hora local",
    "message": "Mensaje",
    "view_in_network": "Ver en red",
    "programs": "Programas",
    "additional_actions": "Acciones adicionales",
    "archive_chat": "Archivar chat",
    "block_user": "Bloquear usuario",
    "unblock_user": "Desbloquear usuario",
    "newMessage": "Nuevo mensaje",
    "searchClassmates": "Buscar compañeros de clase y compañeros de chat recientes...",
    "suggested": "Sugerido",
    "thisConversation1": "Esta conversación es solo entre ",
    "thisConversation2": " y tú. Consulta su perfil para saber más sobre ellos.",
    "beginningOfHistory": "Este es el comienzo de tu historial de mensajes con ",
    "period": ".",
    "and": " y ",
    "comma": " , ",
    "viewProfile": "Ver perfil",
    "toInput": "A:",
    "admin": "Administración",
    "you": "Tú",
    "repliedTo": "{current} respondió a {parent}",
    "replyTo": "Responder al mensaje",
    "replyToAdmin": "Responder al administrador",
    "replyToName": "Responder a {name}",
    "messageTo": "Mensaje {name}",
    "messageGroup": "Grupo de mensajes",
    "startNewMessage": "Iniciar un nuevo mensaje",
    "files": "archivos",
    "chat_information": "Información del chat",
    "and_n_others": "y {count} otros",
    "members": "Miembros",
    "leave_chat": "Salir del chat",
    "location_not_set": "Ubicación no establecida",
    "search_placeholder": "Buscar...",
    "from": "De",
    "to": "A",
    "most_relevant": "Lo más relevante",
    "oldest_to_newest": "Del más antiguo al más nuevo",
    "newest_to_oldest": "Del más nuevo al más antiguo",
    "in_chat": "En el chat",
    "search_all_chats": "Buscar todos los chats",
    "search_this_chat": "Buscar en este chat",
    "this_chat": "Este Chat",
    "all_chats": "Todos los chats",
    "profile_not_available": "Perfil no disponible",
    "reply": "Responder",
    "copy": "Copiar",
    "delete": "Borrar",
    "confirm_delete_this_message": "¿Borrar este mensaje?",
    "cancel": "Cancelar",
    "delete_message": "Eliminar mensaje",
    "cannot_delete_message_with_replies": "Este mensaje no se puede eliminar porque tiene una respuesta.",
    "error_deleting_message": "Error al eliminar el mensaje",
    "somethingWentWrong": "Algo salió mal",
    "engineersNotified": "Se ha notificado a los ingenieros",
    "closeAndRetry": "Cerrar y volver a intentarlo",
    "deactivated": "desactivado",
    "failedMessage": "Error al enviar"
}
};window.locales.it = window.locales.it || {};window.locales.it.messaging = window.locales.it.messaging || {};window.locales.it.messaging.messaging = window.locales.it.messaging.messaging || {};window.locales.it.messaging.messaging = {...window.locales.it.messaging.messaging, ...{
    "messages": "Messaggi",
    "search": "Ricerca",
    "reset": "Reset",
    "more": "Di più",
    "no_results": "Nessun risultato",
    "load_more": "Carica altro",
    "order": "Ordine",
    "chats": "Le chat",
    "image": "Immagine",
    "video": "Video",
    "file": "File",
    "today": "Oggi",
    "yesterday": "Ieri",
    "Sunday": "Domenica",
    "Monday": "Lunedi",
    "Tuesday": "Martedì",
    "Wednesday": "Mercoledì",
    "Thursday": "Giovedì",
    "Friday": "Venerdì",
    "Saturday": "Sabato",
    "formattedDate": "{formattedDate}",
    "no_members": "Nessun membro",
    "profile": "Profilo",
    "online": "In linea",
    "offline": "Fuori linea",
    "local_time": "ora locale",
    "message": "Messaggio",
    "view_in_network": "Visualizza in rete",
    "programs": "Programmi",
    "additional_actions": "Azioni aggiuntive",
    "archive_chat": "Archivio Chat",
    "block_user": "Blocca utente",
    "unblock_user": "Sblocca utente",
    "newMessage": "Nuovo messaggio",
    "searchClassmates": "Cerca compagni di classe e interlocutori di chat recenti...",
    "suggested": "Suggerito",
    "thisConversation1": "Questa conversazione è solo tra ",
    "thisConversation2": " e tu. Dai un'occhiata al loro profilo per saperne di più su di loro.",
    "beginningOfHistory": "Questo è l'inizio della cronologia dei tuoi messaggi con ",
    "period": ".",
    "and": " E ",
    "comma": " , ",
    "viewProfile": "Visualizza profilo",
    "toInput": "A:",
    "admin": "Amministratore",
    "you": "Voi",
    "repliedTo": "{current} ha risposto a {parent}",
    "replyTo": "Rispondi al messaggio",
    "replyToAdmin": "Rispondi all'amministratore",
    "replyToName": "Rispondi a {name}",
    "messageTo": "Messaggio {name}",
    "messageGroup": "Gruppo di messaggi",
    "startNewMessage": "Inizia un nuovo messaggio",
    "files": "file",
    "chat_information": "Informazioni sulla chat",
    "and_n_others": "e {count} altri",
    "members": "Membri",
    "leave_chat": "Lascia la chat",
    "location_not_set": "Posizione non impostata",
    "search_placeholder": "Ricerca...",
    "from": "Da",
    "to": "A",
    "most_relevant": "Più rilevanti",
    "oldest_to_newest": "Dal più vecchio al più nuovo",
    "newest_to_oldest": "Dal più recente al più vecchio",
    "in_chat": "Nella chat",
    "search_all_chats": "Cerca tutte le chat",
    "search_this_chat": "Cerca questa chat",
    "this_chat": "Questa chat",
    "all_chats": "Tutte le chat",
    "profile_not_available": "Profilo non disponibile",
    "reply": "Rispondere",
    "copy": "Copia",
    "delete": "Eliminare",
    "confirm_delete_this_message": "Vuoi eliminare questo messaggio?",
    "cancel": "Cancellare",
    "delete_message": "Elimina messaggio",
    "cannot_delete_message_with_replies": "Questo messaggio non può essere eliminato perché ha una risposta.",
    "error_deleting_message": "Errore durante l'eliminazione del messaggio",
    "somethingWentWrong": "Qualcosa è andato storto",
    "engineersNotified": "Gli ingegneri sono stati avvisati",
    "closeAndRetry": "Chiudi e riprova",
    "deactivated": "disattivato",
    "failedMessage": "Impossibile inviare"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.messaging = window.locales.zh.messaging || {};window.locales.zh.messaging.messaging = window.locales.zh.messaging.messaging || {};window.locales.zh.messaging.messaging = {...window.locales.zh.messaging.messaging, ...{
    "messages": "消息",
    "search": "搜索",
    "reset": "重置",
    "more": "更多的",
    "no_results": "没有结果",
    "load_more": "加载更多",
    "order": "命令",
    "chats": "聊天",
    "image": "图像",
    "video": "视频",
    "file": "文件",
    "today": "今天",
    "yesterday": "昨天",
    "Sunday": "星期日",
    "Monday": "周一",
    "Tuesday": "周二",
    "Wednesday": "周三",
    "Thursday": "周四",
    "Friday": "星期五",
    "Saturday": "周六",
    "formattedDate": "{formattedDate}",
    "no_members": "没有会员",
    "profile": "轮廓",
    "online": "在线的",
    "offline": "离线",
    "local_time": "当地时间",
    "message": "信息",
    "view_in_network": "在网络中查看",
    "programs": "程序",
    "additional_actions": "其他操作",
    "archive_chat": "存档聊天",
    "block_user": "阻止用户",
    "unblock_user": "解除阻止用户",
    "newMessage": "新消息",
    "searchClassmates": "搜索同学和最近的聊天伙伴...",
    "suggested": "建议",
    "thisConversation1": "这次对话只是 ",
    "thisConversation2": ", 和你。查看他们的个人资料以了解更多有关他们的信息。",
    "beginningOfHistory": "这是您与 ",
    "period": "。",
    "and": " 和 ",
    "comma": " ， ",
    "viewProfile": "查看个人资料",
    "toInput": "到：",
    "admin": "行政",
    "you": "你",
    "repliedTo": "{current}回复了{parent}",
    "replyTo": "回复消息",
    "replyToAdmin": "回复管理员",
    "replyToName": "回复{name}",
    "messageTo": "留言{name}",
    "messageGroup": "消息组",
    "startNewMessage": "开始新消息",
    "files": "文件",
    "chat_information": "聊天信息",
    "and_n_others": "以及{count}个其他人员",
    "members": "成员",
    "leave_chat": "离开聊天",
    "location_not_set": "未设置位置",
    "search_placeholder": "搜索...",
    "from": "从",
    "to": "到",
    "most_relevant": "最相关",
    "oldest_to_newest": "从最旧到最新",
    "newest_to_oldest": "从最新到最旧",
    "in_chat": "在聊天中",
    "search_all_chats": "搜索所有聊天",
    "search_this_chat": "搜索此聊天",
    "this_chat": "本次聊天",
    "all_chats": "所有聊天",
    "profile_not_available": "个人资料不可用",
    "reply": "回复",
    "copy": "复制",
    "delete": "删除",
    "confirm_delete_this_message": "删除此消息吗？",
    "cancel": "取消",
    "delete_message": "删除消息",
    "cannot_delete_message_with_replies": "由于此消息已有回复，因此无法删除。",
    "error_deleting_message": "删除消息时出错",
    "somethingWentWrong": "出了点问题",
    "engineersNotified": "工程师已收到通知",
    "closeAndRetry": "关闭并重试",
    "deactivated": "停用",
    "failedMessage": "发送失败"
}
};