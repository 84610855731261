window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.app_header_view_model = window.locales.am.navigation.app_header_view_model || {};window.locales.am.navigation.app_header_view_model = {...window.locales.am.navigation.app_header_view_model, ...{
    "begin_lesson": "ትምህርት ይጀምሩ",           
    "begin_test": "ፈተና ይጀምሩ",           
    "lesson_complete": "ትምህርት ተጠናቅቋል",           
    "test_complete": "ፈተና ተጠናቋል",           
    "screen_complete": "ፍተሻ ተጠናቅቋል"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.app_header_view_model = window.locales.ar.navigation.app_header_view_model || {};window.locales.ar.navigation.app_header_view_model = {...window.locales.ar.navigation.app_header_view_model, ...{
    "begin_lesson": "ابدأ الدرس",           
    "lesson_complete": "اكتمل الدرس",           
    "screen_complete": "إكمال الشاشة",           
    "begin_test": "بدء الاختبار",
    "test_complete": "اختبار كاملة"
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.app_header_view_model = window.locales.en.navigation.app_header_view_model || {};window.locales.en.navigation.app_header_view_model = {...window.locales.en.navigation.app_header_view_model, ...{
    "begin_lesson": "Begin Lesson",
    "begin_test": "Begin Test",
    "lesson_complete": "Lesson Complete",
    "test_complete": "Test Complete",
    "screen_complete": "Screen complete"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.app_header_view_model = window.locales.es.navigation.app_header_view_model || {};window.locales.es.navigation.app_header_view_model = {...window.locales.es.navigation.app_header_view_model, ...{
    "begin_lesson": "Comenzar Lección",           
    "lesson_complete": "Lección Completa",           
    "screen_complete": "Pantalla completa",           
    "begin_test": "Iniciar prueba",
    "test_complete": "prueba completa"
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.app_header_view_model = window.locales.it.navigation.app_header_view_model || {};window.locales.it.navigation.app_header_view_model = {...window.locales.it.navigation.app_header_view_model, ...{
    "begin_lesson": "Inizia Lezione",           
    "begin_test": "Inizia Test",           
    "lesson_complete": "Lezione completata",           
    "test_complete": "Test completato",           
    "screen_complete": "Schermata completata"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.app_header_view_model = window.locales.zh.navigation.app_header_view_model || {};window.locales.zh.navigation.app_header_view_model = {...window.locales.zh.navigation.app_header_view_model, ...{
    "begin_lesson": "开始课业",           
    "lesson_complete": "课业完成",           
    "screen_complete": "屏幕完成",           
    "begin_test": "开始测试",           
    "test_complete": "测试完成"           
}
};