window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.app_header = window.locales.am.navigation.app_header || {};window.locales.am.navigation.app_header = {...window.locales.am.navigation.app_header, ...{
    "back": "ወደኋላ",           
    "chapter_number_x": "ምዕራፍ {{number}} •",           
    "close": "ዝጋ",           
    "exit": "መውጫ",           
    "last_saved_by_x_at_x": "ለመጨረሻ ጊዜ የተቀመጠው {{editor}} በ {{updatedAt}} (አልተለጠፈም)",           
    "logged_in_as_x": "በመለያ የተገባው እንደ <strong>{{email}}ነው።</strong> ወደ ተጠቃሚዎችዎ ለመመለስ ጠንካራ ማደስ (Cmd+R)።",           
    "mba": "MBA ያሳዩ",           
    "playlists": "የአጫዋች ዝርዝሮችን ያሳዩ",           
    "save_and_exit": "ያስቀምጡ እና ይወጡ",           
    "saved_by_x_at_x": "በ {{editor}} በ {{updatedAt}} ተቀምጧል",           
    "menu": "ዝርዝር<i class=\"fa fa-bars\"></i>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.app_header = window.locales.ar.navigation.app_header || {};window.locales.ar.navigation.app_header = {...window.locales.ar.navigation.app_header, ...{
    "back": "الرجوع",           
    "chapter_number_x": "الفصل {{number}} •",           
    "close": "غلق",           
    "exit": "الخروج",           
    "last_saved_by_x_at_x": "تم الحفظ آخر مرة بواسطة {{editor}} في {{updatedAt}} (غير مثبت)",           
    "logged_in_as_x": "تم تسجيل الدخول كـ <strong>{{email}}.</strong>. تحديث قوي (Cmd+R) للعودة إلى حساب المستخدم الخاص بك.",           
    "mba": "عرض MBA",           
    "playlists": "عرض قوائم التشغيل",           
    "save_and_exit": "حفظ وخروج",           
    "saved_by_x_at_x": "تم الحفظ بواسطة {{editor}} في {{updatedAt}}",           
    "menu": "قائمة<i class=\"fa fa-bars\"></i>"
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.app_header = window.locales.en.navigation.app_header || {};window.locales.en.navigation.app_header = {...window.locales.en.navigation.app_header, ...{
    "back": "BACK",
    "chapter_number_x": "Chapter {{number}} •",
    "close": "CLOSE",
    "exit": "EXIT",
    "last_saved_by_x_at_x": "Last saved by {{editor}} at {{updatedAt}} (not pinned)",
    "logged_in_as_x": "Logged in as <strong>{{email}}.</strong> Hard refresh (Cmd+R) to return to your user.",
    "mba": "SHOW MBA",
    "playlists": "SHOW PLAYLISTS",
    "save_and_exit": "Save and Exit",
    "saved_by_x_at_x": "Saved by {{editor}} at {{updatedAt}}",
    "menu": "MENU <i class=\"fa fa-bars\"></i>"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.app_header = window.locales.es.navigation.app_header || {};window.locales.es.navigation.app_header = {...window.locales.es.navigation.app_header, ...{
    "back": "REGRESAR",           
    "chapter_number_x": "Capítulo {{number}} •",           
    "close": "CERRAR",           
    "exit": "SALIR",           
    "last_saved_by_x_at_x": "Guardado por última vez por {{editor}} en {{updatedAt}} (no marcado)",           
    "logged_in_as_x": "Sesión iniciada como <strong>{{email}}.</strong> Actualización forzada (Cmd+R) para volver a tu usuario.",           
    "mba": "MOSTRAR MBA",           
    "playlists": "PLAYLISTS",           
    "save_and_exit": "Guardar y Salir",           
    "saved_by_x_at_x": "Guardado por {{editor}} en {{updatedAt}}",           
    "menu": "MENÚ<i class=\"fa fa-bars\"></i>"
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.app_header = window.locales.it.navigation.app_header || {};window.locales.it.navigation.app_header = {...window.locales.it.navigation.app_header, ...{
    "back": "INDIETRO",           
    "chapter_number_x": "Capitolo {{number}} •",           
    "close": "CHIUDI",           
    "exit": "ESCI",           
    "last_saved_by_x_at_x": "Ultimo salvataggio da {{editor}} alle {{updatedAt}} (non bloccato)",           
    "logged_in_as_x": "Accesso effettuato come <strong>{{email}}.</strong> Esegui un aggiornamento forzato (Cmd+R) per tornare al tuo utente.",           
    "mba": "MOSTRA MBA",           
    "playlists": "MOSTRA PLAYLIST",           
    "save_and_exit": "Salva e Esci",           
    "saved_by_x_at_x": "Inizia Lezione",           
    "menu": "MENÙ<i class=\"fa fa-bars\"></i>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.app_header = window.locales.zh.navigation.app_header || {};window.locales.zh.navigation.app_header = {...window.locales.zh.navigation.app_header, ...{
    "back": "后退",           
    "chapter_number_x": "第 {{number}} 章",           
    "close": "关闭",           
    "exit": "退出",           
    "last_saved_by_x_at_x": "最后保存者 {{editor}} 于 {{updatedAt}}（未固定）",           
    "logged_in_as_x": "已以 <strong>{{email}} 身份登录。</strong>硬刷新 (Cmd+R) 可返回到您的用户。",           
    "mba": "显示 MBA",           
    "playlists": "显示任务列表",           
    "save_and_exit": "保存并退出",           
    "saved_by_x_at_x": "保存者 {{editor}} 于 {{updatedAt}}",           
    "menu": "菜单<i class=\"fa fa-bars\"></i>"
}
};