window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.OfflineMode = window.locales.am.OfflineMode || {};window.locales.am.OfflineMode.showOfflineModal = window.locales.am.OfflineMode.showOfflineModal || {};window.locales.am.OfflineMode.showOfflineModal = {...window.locales.am.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "ወደ ዳሽቦርድዎ ለመቀጠል እና ከመስመር ውጪ ኮርሶችን ለመመልከት ጠቅ ያድርጉ።",           
    "continue": "ቀጥል",           
    "only_offline_courses_available": "እንደገና እስከሚገናኙ ድረስ፣ ከመስመር ውጪ ለሆነ ጥቅም የተቀመጡ ኮርሶችን ብቻ መመልከት ይችላሉ።  ለመቀጠል ጠቅ ያድርጉ።",           
    "you_are_offline": "ከመስመር ውጪ ነዎት"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.OfflineMode = window.locales.ar.OfflineMode || {};window.locales.ar.OfflineMode.showOfflineModal = window.locales.ar.OfflineMode.showOfflineModal || {};window.locales.ar.OfflineMode.showOfflineModal = {...window.locales.ar.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "انقر للمتابعة إلى لوحة التحكم وعرض الدورات المتوفرة في وضع عدم الاتصال بالانترنت.",           
    "continue": "استمر",           
    "only_offline_courses_available": "إلى أن تتصل بالانترنت ، ستتمكن فقط من عرض الدورات التي تم تخزينها للاستخدام في وضع عدم الاتصال. انقر للمتابعة.",           
    "you_are_offline": "أنت غير متصل بالانترنت"           
}
};window.locales.en = window.locales.en || {};window.locales.en.OfflineMode = window.locales.en.OfflineMode || {};window.locales.en.OfflineMode.showOfflineModal = window.locales.en.OfflineMode.showOfflineModal || {};window.locales.en.OfflineMode.showOfflineModal = {...window.locales.en.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "Click to continue to your dashboard and view offline courses.",
    "continue": "Continue",
    "only_offline_courses_available": "Until you reconnect, you will only be able to view courses that have been stored for offline use.  Click to continue.",
    "you_are_offline": "You are offline"
}
};window.locales.es = window.locales.es || {};window.locales.es.OfflineMode = window.locales.es.OfflineMode || {};window.locales.es.OfflineMode.showOfflineModal = window.locales.es.OfflineMode.showOfflineModal || {};window.locales.es.OfflineMode.showOfflineModal = {...window.locales.es.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "Haga clic para continuar a su tablero y ver cursos fuera de línea.",
    "continue": "Continuar",           
    "only_offline_courses_available": "Hasta que vuelva a conectarse, solo podrá ver los cursos que se han almacenado para su uso sin conexión. Haz click para continuar.",
    "you_are_offline": "Estas desconectado"
}
};window.locales.it = window.locales.it || {};window.locales.it.OfflineMode = window.locales.it.OfflineMode || {};window.locales.it.OfflineMode.showOfflineModal = window.locales.it.OfflineMode.showOfflineModal || {};window.locales.it.OfflineMode.showOfflineModal = {...window.locales.it.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "Fai clic per passare alla dashboard e visualizzare i corsi offline.",
    "continue": "Continua",           
    "only_offline_courses_available": "Fino a quando non ti riconnetti, sarai in grado di visualizzare solo i corsi che sono stati memorizzati per l'uso offline. Clicca per continuare.",
    "you_are_offline": "Sei offline"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.OfflineMode = window.locales.zh.OfflineMode || {};window.locales.zh.OfflineMode.showOfflineModal = window.locales.zh.OfflineMode.showOfflineModal || {};window.locales.zh.OfflineMode.showOfflineModal = {...window.locales.zh.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "单击可继续查看仪表板并查看离线课程。",           
    "continue": "继续",           
    "only_offline_courses_available": "在重新连接之前，您只能查看存储为离线使用的课程。  点击继续。",           
    "you_are_offline": "你已离线。"           
}
};