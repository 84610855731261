import { UserOnlineState, type User } from '@sendbird/chat';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import defaultAvatarSrc from 'vectors/profile_photo_default.svg';
import { useGetConnectionStatus } from '../utils';

type Props = {
    members: User[];
    showOnlineStatus?: boolean;
    className?: string;
    onlineIndicatorOuterClassName?: string;
    onlineIndicatorInnerClassName?: string;
};

const classes = {
    avatar: {
        container: clsx(
            'block',
            'relative',
            'h-[56px]',
            'w-[56px]',
            'rounded-full',
            'bg-center',
            'bg-cover',
            'bg-no-repeat',
            'bg-transparent',
        ),
        onlineIndicator: (isOnline: boolean) => ({
            outer: clsx(
                'w-[14px]',
                'h-[14px]',
                'rounded-full',
                'border-2',
                'border-white',
                'absolute',
                'flex',
                'justify-center',
                'items-center',
                'bottom-0',
                'right-0',
                'border-solid',
                { 'bg-white': !isOnline },
                { 'bg-green-600': isOnline },
            ),
            inner: clsx(
                'block',
                'min-w-[11px]',
                'min-h-[11px]',
                'rounded-full',
                'border',
                'border-solid',
                { 'border-transparent bg-green-600': isOnline },
                { 'border-slate-grey bg-transparent': !isOnline },
            ),
        }),
    },
    multiAvatar: {
        person1Avatar: clsx(
            'absolute',
            'bottom-0',
            'left-0',
            'h-[65%]',
            'w-[65%]',
            'rounded-full',
            'bg-center',
            'bg-cover',
            'bg-no-repeat',
            'bg-white',
            'border-2',
            'border-white',
            'border-solid',
            'z-10',
        ),
        person2Avatar: clsx(
            'absolute',
            'top-0',
            'right-0',
            'h-[65%]',
            'w-[65%]',
            'rounded-full',
            'bg-center',
            'bg-cover',
            'bg-no-repeat',
            'bg-white',
            'border-2',
            'border-white',
            'border-solid',
            'z-0',
        ),
    },
};

export const MemberAvatars = ({
    members,
    showOnlineStatus = false,
    className,
    onlineIndicatorInnerClassName,
    onlineIndicatorOuterClassName,
}: Props) => {
    const getConnectionStatus = useGetConnectionStatus();

    const isOnline = getConnectionStatus(members[0]) === UserOnlineState.ONLINE;

    return (
        <>
            {members.length === 0 && (
                <span
                    className={twMerge(classes.avatar.container, className)}
                    style={{
                        backgroundImage: `url(${defaultAvatarSrc})`,
                    }}
                />
            )}

            {members.length === 1 && (
                <span
                    className={twMerge(classes.avatar.container, className)}
                    style={{
                        backgroundImage: `url(${members[0].plainProfileUrl}), url(${defaultAvatarSrc})`,
                    }}
                >
                    {showOnlineStatus && (
                        <span
                            className={twMerge(
                                classes.avatar.onlineIndicator(isOnline).outer,
                                onlineIndicatorOuterClassName,
                            )}
                        >
                            <span
                                className={twMerge(
                                    classes.avatar.onlineIndicator(isOnline).inner,
                                    onlineIndicatorInnerClassName,
                                )}
                            />
                        </span>
                    )}
                </span>
            )}

            {members.length > 1 && (
                <span className={twMerge(classes.avatar.container, className)}>
                    {/* Show the avatars of the first two channel members for groups */}
                    <span
                        className={classes.multiAvatar.person1Avatar}
                        style={{
                            backgroundImage: `url(${members[0].plainProfileUrl}), url(${defaultAvatarSrc})`,
                        }}
                    />
                    <span
                        className={classes.multiAvatar.person2Avatar}
                        style={{
                            backgroundImage: `url(${members[1].plainProfileUrl}), url(${defaultAvatarSrc})`,
                        }}
                    />
                </span>
            )}
        </>
    );
};
