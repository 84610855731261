window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.app_menu_user = window.locales.am.navigation.app_menu_user || {};window.locales.am.navigation.app_menu_user = {...window.locales.am.navigation.app_menu_user, ...{
    "account": "መለያ",           
    "profile": "ግለ-መገለጫ",           
    "preferences": "ምርጫዎች",           
    "give_feedback": "ግብረ መልስ ይስጡ",           
    "sign_out": "ይዉጡ",           
    "why_us": "ለምን{{brandName}}",           
    "mba_curriculum": "የ MBA ካሪኩለም",           
    "for_companies": "ለኩባንያዎች",           
    "method": "ዘዴ",           
    "login": "ይግቡ",           
    "billing": "ክፍያ",           
    "program": "ፕሮግራም",
    "settings": "ቅንብሮች"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.app_menu_user = window.locales.ar.navigation.app_menu_user || {};window.locales.ar.navigation.app_menu_user = {...window.locales.ar.navigation.app_menu_user, ...{
    "preferences": "التفضيلات",           
    "give_feedback": "امنحنا آرائك واقتراحاتك",           
    "sign_out": "الخروج",           
    "mba_curriculum": "مناهج MBA",           
    "for_companies": "للشركات",           
    "method": "الطريقة",           
    "login": "تسجيل الدخول",           
    "billing": "الفواتير",
    "account": "الحساب",
    "profile": "الملف الشخصي",
    "why_us": "لماذا {{brandName}}",
    "program": "برنامج",
    "settings": "إعدادات"
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.app_menu_user = window.locales.en.navigation.app_menu_user || {};window.locales.en.navigation.app_menu_user = {...window.locales.en.navigation.app_menu_user, ...{
    "account": "Account",
    "profile": "Profile",
    "preferences": "Preferences",
    "give_feedback": "Give Feedback",
    "sign_out": "Sign Out",
    "why_us": "Why {{brandName}}",
    "mba_curriculum": "MBA Curriculum",
    "for_companies": "For Companies",
    "method": "Method",
    "login": "Login",
    "billing": "Billing",
    "program": "Program",
    "settings": "Settings"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.app_menu_user = window.locales.es.navigation.app_menu_user || {};window.locales.es.navigation.app_menu_user = {...window.locales.es.navigation.app_menu_user, ...{
    "preferences": "Preferencias",           
    "give_feedback": "Dejar comentarios",           
    "sign_out": "Desconectarse",           
    "mba_curriculum": "Plan de estudios del MBA",           
    "for_companies": "Para Empresas",           
    "method": "Método",           
    "login": "Iniciar sesión",           
    "billing": "Facturación",
    "account": "Cuenta",
    "profile": "Perfil",
    "why_us": "Por qué {{brandName}}",
    "program": "Programa",
    "settings": "Ajustes"
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.app_menu_user = window.locales.it.navigation.app_menu_user || {};window.locales.it.navigation.app_menu_user = {...window.locales.it.navigation.app_menu_user, ...{
    "preferences": "Preferenze",           
    "give_feedback": "Invia Feedback",           
    "sign_out": "Esci",           
    "mba_curriculum": "Curriculum MBA",           
    "for_companies": "Per le Aziende",           
    "method": "Metodo",           
    "login": "Accedi",           
    "billing": "Fatturazione",
    "account": "Account",
    "profile": "Profilo",
    "why_us": "Perché {{brandName}}",
    "program": "Programma",
    "settings": "Impostazioni"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.app_menu_user = window.locales.zh.navigation.app_menu_user || {};window.locales.zh.navigation.app_menu_user = {...window.locales.zh.navigation.app_menu_user, ...{
    "preferences": "偏好设置",           
    "give_feedback": "提供反馈",           
    "sign_out": "注销",           
    "mba_curriculum": "MBA 课程",           
    "for_companies": "对于公司",           
    "method": "方法",           
    "login": "登录",           
    "billing": "计费",           
    "account": "账户",           
    "profile": "简介",           
    "why_us": "为何选择{{brandName}}",           
    "program": "程序",
    "settings": "设置"
}
};