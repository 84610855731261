window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.sharing_buttons = window.locales.am.navigation.sharing_buttons || {};window.locales.am.navigation.sharing_buttons = {...window.locales.am.navigation.sharing_buttons, ...{
    "email": "ኢሜይል",           
    "share": "ያጋሩ",           
    "tweet": "ትዊት"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.sharing_buttons = window.locales.ar.navigation.sharing_buttons || {};window.locales.ar.navigation.sharing_buttons = {...window.locales.ar.navigation.sharing_buttons, ...{
    "email": "البريد الإلكتروني",           
    "share": "شارِك",           
    "tweet": "غرّد"           
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.sharing_buttons = window.locales.en.navigation.sharing_buttons || {};window.locales.en.navigation.sharing_buttons = {...window.locales.en.navigation.sharing_buttons, ...{
    "email": "Email",
    "share": "Share",
    "tweet": "Tweet"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.sharing_buttons = window.locales.es.navigation.sharing_buttons || {};window.locales.es.navigation.sharing_buttons = {...window.locales.es.navigation.sharing_buttons, ...{
    "email": "Correo electrónico",           
    "share": "Compartir",           
    "tweet": "Tweet"           
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.sharing_buttons = window.locales.it.navigation.sharing_buttons || {};window.locales.it.navigation.sharing_buttons = {...window.locales.it.navigation.sharing_buttons, ...{
    "email": "Email",           
    "share": "Condividi",           
    "tweet": "Twitta"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.sharing_buttons = window.locales.zh.navigation.sharing_buttons || {};window.locales.zh.navigation.sharing_buttons = {...window.locales.zh.navigation.sharing_buttons, ...{
    "email": "电子邮件",           
    "share": "分享",           
    "tweet": "Twitter 发帖"           
}
};