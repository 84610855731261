window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.previous_button_desktop = window.locales.am.navigation.previous_button_desktop || {};window.locales.am.navigation.previous_button_desktop = {...window.locales.am.navigation.previous_button_desktop, ...{
    "previous": "ቀዳሚ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.previous_button_desktop = window.locales.ar.navigation.previous_button_desktop || {};window.locales.ar.navigation.previous_button_desktop = {...window.locales.ar.navigation.previous_button_desktop, ...{
    "previous": "السابق"           
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.previous_button_desktop = window.locales.en.navigation.previous_button_desktop || {};window.locales.en.navigation.previous_button_desktop = {...window.locales.en.navigation.previous_button_desktop, ...{
    "previous": "Previous"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.previous_button_desktop = window.locales.es.navigation.previous_button_desktop || {};window.locales.es.navigation.previous_button_desktop = {...window.locales.es.navigation.previous_button_desktop, ...{
    "previous": "Anterior"           
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.previous_button_desktop = window.locales.it.navigation.previous_button_desktop || {};window.locales.it.navigation.previous_button_desktop = {...window.locales.it.navigation.previous_button_desktop, ...{
    "previous": "Indietro"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.previous_button_desktop = window.locales.zh.navigation.previous_button_desktop || {};window.locales.zh.navigation.previous_button_desktop = {...window.locales.zh.navigation.previous_button_desktop, ...{
    "previous": "上一步"           
}
};