window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.mobileFileUpload = window.locales.am.mobileFileUpload || {};window.locales.am.mobileFileUpload.mobileFileUpload = window.locales.am.mobileFileUpload.mobileFileUpload || {};window.locales.am.mobileFileUpload.mobileFileUpload = {...window.locales.am.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "ሰርዝ",
    "photo": "ፎቶ",
    "fromPhotos": "ከፎቶዎች",
    "takePicture": "ፎቶ አንሳ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.mobileFileUpload = window.locales.ar.mobileFileUpload || {};window.locales.ar.mobileFileUpload.mobileFileUpload = window.locales.ar.mobileFileUpload.mobileFileUpload || {};window.locales.ar.mobileFileUpload.mobileFileUpload = {...window.locales.ar.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "يلغي",
    "photo": "صورة",
    "fromPhotos": "من الصور",
    "takePicture": "التقط صورة"
}
};window.locales.en = window.locales.en || {};window.locales.en.mobileFileUpload = window.locales.en.mobileFileUpload || {};window.locales.en.mobileFileUpload.mobileFileUpload = window.locales.en.mobileFileUpload.mobileFileUpload || {};window.locales.en.mobileFileUpload.mobileFileUpload = {...window.locales.en.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "Cancel",
    "photo": "Photo",
    "fromPhotos": "From Photos",
    "takePicture": "Take Picture"
}
};window.locales.es = window.locales.es || {};window.locales.es.mobileFileUpload = window.locales.es.mobileFileUpload || {};window.locales.es.mobileFileUpload.mobileFileUpload = window.locales.es.mobileFileUpload.mobileFileUpload || {};window.locales.es.mobileFileUpload.mobileFileUpload = {...window.locales.es.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "Cancelar",
    "photo": "Foto",
    "fromPhotos": "De fotos",
    "takePicture": "Tomar fotografía"
}
};window.locales.it = window.locales.it || {};window.locales.it.mobileFileUpload = window.locales.it.mobileFileUpload || {};window.locales.it.mobileFileUpload.mobileFileUpload = window.locales.it.mobileFileUpload.mobileFileUpload || {};window.locales.it.mobileFileUpload.mobileFileUpload = {...window.locales.it.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "Cancellare",
    "photo": "Foto",
    "fromPhotos": "Dalle foto",
    "takePicture": "Scatta una foto"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.mobileFileUpload = window.locales.zh.mobileFileUpload || {};window.locales.zh.mobileFileUpload.mobileFileUpload = window.locales.zh.mobileFileUpload.mobileFileUpload || {};window.locales.zh.mobileFileUpload.mobileFileUpload = {...window.locales.zh.mobileFileUpload.mobileFileUpload, ...{
    "cancel": "取消",
    "photo": "照片",
    "fromPhotos": "来自照片",
    "takePicture": "拍照"
}
};