window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.navigation = window.locales.am.navigation || {};window.locales.am.navigation.section_navigation = window.locales.am.navigation.section_navigation || {};window.locales.am.navigation.section_navigation = {...window.locales.am.navigation.section_navigation, ...{
    "header": "የክፍል አሰሳ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.navigation = window.locales.ar.navigation || {};window.locales.ar.navigation.section_navigation = window.locales.ar.navigation.section_navigation || {};window.locales.ar.navigation.section_navigation = {...window.locales.ar.navigation.section_navigation, ...{
    "header": "تصفح القسم"           
}
};window.locales.en = window.locales.en || {};window.locales.en.navigation = window.locales.en.navigation || {};window.locales.en.navigation.section_navigation = window.locales.en.navigation.section_navigation || {};window.locales.en.navigation.section_navigation = {...window.locales.en.navigation.section_navigation, ...{
    "header": "SECTION NAVIGATION"
}
};window.locales.es = window.locales.es || {};window.locales.es.navigation = window.locales.es.navigation || {};window.locales.es.navigation.section_navigation = window.locales.es.navigation.section_navigation || {};window.locales.es.navigation.section_navigation = {...window.locales.es.navigation.section_navigation, ...{
    "header": "SECCIÓN NAVEGACIÓN"           
}
};window.locales.it = window.locales.it || {};window.locales.it.navigation = window.locales.it.navigation || {};window.locales.it.navigation.section_navigation = window.locales.it.navigation.section_navigation || {};window.locales.it.navigation.section_navigation = {...window.locales.it.navigation.section_navigation, ...{
    "header": "NAVIGAZIONE AREA"           
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.navigation = window.locales.zh.navigation || {};window.locales.zh.navigation.section_navigation = window.locales.zh.navigation.section_navigation || {};window.locales.zh.navigation.section_navigation = {...window.locales.zh.navigation.section_navigation, ...{
    "header": "栏目导航"           
}
};