import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { forwardRef, type SelectHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
    children: React.ReactNode;
    className?: string;
    inputClassName?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

/**
 * A custom select component with a wrapper and positioned chevron icon
 * since adding padding to a select element itself does not move the native chevron.
 */
export const Select = forwardRef<HTMLSelectElement, Props>(
    ({ children, className, inputClassName, ...rest }: Props, ref) => (
        <div
            className={twMerge(
                'select-wrapper',
                'relative',

                className,
            )}
        >
            <select
                ref={ref}
                className={twMerge(
                    'appearance-none',
                    'w-full',
                    'h-full',
                    'pe-[30px]',
                    'ps-2',
                    'py-2',
                    'border',
                    'border-solid',
                    'rounded-md',
                    'border-slate-grey-mid-light',
                    'text-black',
                    'bg-white',
                    inputClassName,
                )}
                {...rest}
            >
                {children}
            </select>
            <div
                className={clsx(
                    'select-icon',
                    'absolute',
                    'top-0',
                    'right-[15px]',
                    'h-full',
                    'flex',
                    'z-1',
                    'items-center',
                    'justify-center',
                    'pointer-events-none',
                )}
            >
                <FontAwesomeIcon icon={faChevronDown} className={clsx('text-black', 'text-[0.7em]')} />
            </div>
        </div>
    ),
);
